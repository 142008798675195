import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pixel Bomber is a simplistic browser-based multiplayer game that lets you battle anyone anywhere for the leaderboard. Move around the map, shoot and place bombs to eliminate other players! `}<a parentName="p" {...{
        "href": "http://pixel.programmer5000.com"
      }}>{`Play it now!`}</a>{`  `}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/bomb-game-frontend"
      }}>{`Pixel Bomber's client side code`}</a>{` is hosted with `}<a parentName="p" {...{
        "href": "https://pages.github.com/"
      }}>{`Github Pages`}</a>{`. `}<a parentName="p" {...{
        "href": "https://firebase.google.com/"
      }}>{`Firebase`}</a>{` is used for (optional) user authentication for persistent username storage and claiming. The client connects to a WebSocket and receives map and user data, and then provides it with updates to movement and controls.  `}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/bomb-game"
      }}>{`Pixel Bomber's server side code`}</a>{` is hosted on Heroku. Game physics, player interaction, etc. are all done on the server side to ensure clients are honest. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      